import React, { useEffect, useState } from 'react';
import {
  computeTimeRemaining,
  getCountdownStringFromTime
} from '../../util/time';
import Container from '../theme/layout';

import splashStyles from '../../sections/Splash.module.css';

const Countdown = () => {
  const hackathonDeadlineUTC = Date.UTC(2019, 10, 17, 15);

  const computeHackathonTimeRemaining = () => {
    return computeTimeRemaining(hackathonDeadlineUTC);
  };

  const [timeRemaining, setTimeRemaining] = useState(
    computeHackathonTimeRemaining()
  );

  useEffect(() => {
    setTimeout(() => {
      setTimeRemaining(computeHackathonTimeRemaining());
    }, 1000);
  }, [timeRemaining]);

  function convertMonetary(inp: string) {
    return inp.replace(/^(\d*?\d{1,6})(\d{2})?$/, (m, whole, dec) => {
      return whole.replace(/\B(?=(\d{3})+(?!\d))/g, '\'');
    });
  }

  return (
    <div
      className={`splash-background-container ${splashStyles.splashBackgroundContainer}`}
    >
      <div className={`splash-container ${splashStyles.splashContainer}`}>
        <Container>
          <div
            className={`splash-text-container ${splashStyles.splashTextContainer}`}
          >
            <div
              className={`splash-logo-container ${splashStyles.splashLogoContainer}`}
            >
              <span
                className={`splash-logo-title ${splashStyles.splashLogoTitle}`}
              >
                USI
              </span>
              <br />
              <span
                className={`splash-logo-title ${splashStyles.splashLogoTitle}`}
              >
                HACKATHON
              </span>
              {[...Array(3)].map((_, index) => (
                <br key={index} />
              ))}
            </div>
            <div>
              <div
                className={`splash-logo-deadline ${splashStyles.splashLogoDeadline}`}
              >
                {convertMonetary((timeRemaining / 1000).toString())} <br />
                seconds left
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Countdown;

import React from 'react';

import Countdown from '../components/countdown/Countdown';
import OrganizerLogos from '../sections/OrganizerLogos';

import './index.module.css';

const CountdownPage = () => (
  <div>
    <Countdown />
    <OrganizerLogos />
  </div>
);

export default CountdownPage;
